import * as React from "react";
import * as d3 from "d3";

const locale = d3.formatLocale({
  decimal: ",",
  thousands: "\u00a0",
  grouping: [3],
  currency: ["", ""],
  minus: "\u2212",
  percent: "\u202f%",
});

const colors = ["#ED903D", "#F2B47F", "#F8E49A", "#8A9195", "#D3D9DD"];

const colorsTW = ["bg-[#ED903D]", "bg-[#F2B47F]", "bg-[#F8E49A]", "bg-[#8A9195]", "bg-[#D3D9DD]"];

const getNextColor = (colors) => {
  let i = 0;

  return () => {
    i++;
    if (i === colors.length) {
      i = 0;
    }
    return colors[i];
  };
};

const data = [
  {
    label: "Газ естественный (природный)",
    value: 36,
  },
  {
    label: "Электрическая энергия",
    value: 29,
  },
  {
    label: "Теплоснабжение и горячее водоснабжение",
    value: 4,
  },
  {
    label: "Топливо дизельное",
    value: 29,
  },
  {
    label: "Бензин автомобильный",
    value: 2,
  },
];

// const center = 1174 + 2233 + 892

const Pie3 = ({ idkey }) => {
  const getLegendColor = getNextColor(colorsTW);

  React.useEffect(() => {
    const selector = `.pie-${idkey}`;
    document.querySelector(selector).innerHTML = "";
    const colorInstans = getNextColor(colors);

    const width = 450;
    const height = 350;
    const margin = 40;
    const radius = Math.min(width, height) / 2 - margin;

    // data.sort((a, b) => {
    //   if (a.value < b.value) {
    //     return 1
    //   }
    //   return -1
    // })

    const dataEntries = data.map((i) => {
      return [i.label, i.value];
    });

    const svg = d3
      .select(`.pie-${idkey}`)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    // Compute the position of each group on the pie:
    const pie = d3
      .pie()
      .sort(null)
      .value((d) => d[1]);

    const data_ready = pie(dataEntries);

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll()
      .data(data_ready)
      .join("path")
      .attr(
        "d",
        d3
          .arc()
          .innerRadius(radius * 0.5) // This is the size of the donut hole
          .outerRadius(radius * 0.7)
      )
      .attr("fill", colorInstans)
      .attr("stroke", "#fff")
      .style("stroke-width", "1px");

    const arc = d3
      .arc()
      .innerRadius(radius * 0.5) // This is the size of the donut hole
      .outerRadius(radius * 0.8);

    const outerArc = d3
      .arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9);
    svg
      .selectAll("allPolylines")
      .data(data_ready)

      .join("polyline")

      .attr("stroke", "black")
      .style("fill", "none")
      .attr("stroke-width", 1)
      .attr("points", function (d) {
        const posA = arc.centroid(d); // line insertion in the slice
        const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
        const posC = outerArc.centroid(d); // Label position = almost the same as posB
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
        posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
        return [posA, posB, posC];
      });

    svg
      .selectAll("allLabels")
      .data(data_ready)
      .join("text")
      .text((d) => locale.format(",")(d.data[1]))
      .attr("transform", function (d) {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = radius * 0.99 * (midangle < Math.PI ? 1 : -1);
        return `translate(${pos})`;
      })
      .style("text-anchor", function (d) {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? "start" : "end";
      });

    const x = d3.scaleLinear().domain([0, 1000]).range([0, width]);
    const y = d3.scaleLinear().range([height, 0]);

    // svg
    //   .append("text")
    //   .text(locale.format("$,")(center))
    //   .attr("transform", "translate(0, 5)")
    //   .style("fill", d => {
    //     return "black"
    //   })
    //   .style("font-size", "32px")
    //   .style('text-anchor', 'middle')

    // svg
    //   .append("text")
    //   .text("человек")
    //   .attr("transform", "translate(0, 25)")
    //   .style("fill", d => {
    //     return "black"
    //   })
    //   .style("font-size", "15px")
    //   .style('text-anchor', 'middle')
  }, [data, idkey]);

  return (
    <div
      className="pieOne--wrapper flex flex-col-reverse md:flex-row-reverse justify-center items-center gap-5"
      todo="мобильный сначала диаграмма, а потом легенда"
    >
      <ul className="list-none pl-0 text-300 my-0 text-sm">
        {data.map((el, i) => (
          <li key={i}>
            <span className={`legend-pseudo ${getLegendColor()}`}></span>
            {el.label}
          </li>
        ))}
      </ul>
      <div className={`pie-${idkey}`}></div>
    </div>
  );
};

export default Pie3;
