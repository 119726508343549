import * as React from "react";
import Hero from "../components/hero";
import Tail from "../components/tail";
import TextBlock from "../components/text";
import Seo from "../components/seo";
import Header2 from "../components/header/header2";
import MGCaption from "../components/MGCaption";
import Pie3 from "../components/histograms/Pie3";
import NaviNext from "../components/NaviNext";
import { getLink } from "../components/helper";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import ScrollTo from "../components/lego/scrollto";
import { fFloat } from "../components/helper";


const App1 = () => {
  const { siteUrl, pathPrefix } = useSiteMetadata();

  return (
    <Tail>
      <Hero>
        <h1>ПРИЛОЖЕНИЕ 1. <br/>К ГОДОВОМУ ОТЧЕТУ АО «МОСГАЗ» ЗА 2021 ГОД</h1>
      </Hero>
      <TextBlock>
        <Header2>
          ИНФОРМАЦИЯ ОБ ОБЪЕМЕ ЭНЕРГЕТИЧЕСКИХ РЕСУРСОВ, ИСПОЛЬЗОВАННЫХ ОБЩЕСТВОМ
          В 2021 ГОДУ
        </Header2>

        <p>
          Общество для осуществления основной деятельности использует
          топливно-энергетические ресурсы на производственные и хозяйственные
          нужды.
        </p>
        <p>Общая стоимость энергоресурсов – 429,98 млн руб.</p>
        <p>Увеличение суммарных затрат на энергоресурсы – 8,1%.</p>

        <MGCaption>
          Сведения об объемах энергетических ресурсов, используемых АО «МОСГАЗ»
        </MGCaption>
      </TextBlock>
      <ScrollTo className="pb-3">
        <table
          className="tBig min-w-[1265px]"
          todo="1265px нормальный размер, задать минимальный под скролл"
        >
          <thead>
            <tr>
              <td rowSpan={2}>Вид ресурса</td>
              <td rowSpan={2}>Ед. изм.</td>
              <td colSpan={2}>Расход за 2019 год</td>
              <td colSpan={2}>Расход за 2020 год</td>
              <td colSpan={2}>Расход за 2021 год</td>
              <td colSpan={4}>Отклонение</td>
            </tr>
            <tr className="not-first-fix">
              <td>Кол-во</td>
              <td>
                В денежном <br />
                выражении, <br />
                тыс. руб. <br />
                (без НДС)
              </td>
              <td>Кол-во</td>
              <td>
                В денежном <br />
                выражении, <br />
                тыс. руб. <br />
                (без НДС)
              </td>
              <td>Кол-во</td>
              <td>
                В денежном <br />
                выражении, <br />
                тыс. руб. <br />
                (без НДС)
              </td>
              <td colSpan={2}>Кол-во</td>
              <td colSpan={2}>
                В денежном <br />
                выражении, <br />
                тыс. руб. <br />
                (без НДС)
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>
                Газ естественный <br />
                (природный)
              </td>
              <td>
                Тыс. м<sup>3</sup>
              </td>
              <td>{fFloat(29_270.19)}</td>
              <td>{fFloat(144_602.15)}</td>
              <td>{fFloat(28_291.94)}</td>
              <td>{fFloat(142_871.81)}</td>
              <td>{fFloat(29_564.01)}</td>
              <td>{fFloat(153_809.07)}</td>
              <td>{fFloat(1272.07)}</td>
              <td>{fFloat(4.5, 1)}%</td>
              <td>{fFloat(10_937.25)}</td>
              <td>{fFloat(7.7, 1)}%</td>
            </tr>

            <tr>
              <td>Электрическая энергия</td>
              <td>кВт∙ч</td>
              <td>{fFloat(17_933_227.44)}</td>
              <td>{fFloat(98_823.84)}</td>
              <td>{fFloat(20_865_995.42)}</td>
              <td>{fFloat(117_447.21)}</td>
              <td>{fFloat(21_794_617.36)}</td>
              <td>{fFloat(127_190.08)}</td>
              <td>{fFloat(928_621.94)}</td>
              <td>{fFloat(4.5, 1)}%</td>
              <td>{fFloat(9742.87)}</td>
              <td>{fFloat(8.3, 1)}%</td>
            </tr>

            <tr>
              <td>Топливо дизельное</td>
              <td>Литр</td>
              <td>{fFloat(3_217_056.54)}</td>
              <td>{fFloat(127_667.35)}</td>
              <td>{fFloat(2_877_135.94)}</td>
              <td>{fFloat(113_982.4)}</td>
              <td>{fFloat(3_221_516.48)}</td>
              <td>{fFloat(123_648.13)}</td>
              <td>{fFloat(344_380.54)}</td>
              <td>{fFloat(12.0, 1)}%</td>
              <td>{fFloat(9665.73)}</td>
              <td>{fFloat(8.5, 1)}%</td>
            </tr>

            <tr>
              <td>Теплоснабжение</td>
              <td>Гкал</td>
              <td>{fFloat(7996.89)}</td>
              <td>{fFloat(12_909.89)}</td>
              <td>{fFloat(6997.6)}</td>
              <td>{fFloat(12_530.83)}</td>
              <td>{fFloat(8490.08)}</td>
              <td>{fFloat(15_680.71)}</td>
              <td>{fFloat(1492.48)}</td>
              <td>{fFloat(21.3, 1)}%</td>
              <td>{fFloat(349.88)}</td>
              <td>{fFloat(25.1, 1)}%</td>
            </tr>

            <tr>
              <td>Бензин автомобильный</td>
              <td>Литр</td>
              <td>{fFloat(242_469.87)}</td>
              <td>{fFloat(9094.9)}</td>
              <td>{fFloat(266_071.19)}</td>
              <td>{fFloat(9751.0)}</td>
              <td>{fFloat(215_407.25)}</td>
              <td>{fFloat(8356.72)}</td>
              <td>{fFloat(-50_663.94)}</td>
              <td>{fFloat(-19.0, 1)}%</td>
              <td>{fFloat(-1394.28)}</td>
              <td>{fFloat(-14.3, 1)}%</td>
            </tr>

            <tr>
              <td>Горячее водоснабжение</td>
              <td>Куб. м</td>
              <td>{fFloat(8357.19)}</td>
              <td>{fFloat(1359.1)}</td>
              <td>{fFloat(7736.97)}</td>
              <td>{fFloat(1299.53)}</td>
              <td>{fFloat(7468.01)}</td>
              <td>{fFloat(1298.23)}</td>
              <td>{fFloat(-268.96)}</td>
              <td>{fFloat(-3.5, 1)}%</td>
              <td>{fFloat(-1.3)}</td>
              <td>{fFloat(-0.1, 1)}%</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3}></td>
              <td>{fFloat(394_457.22)}</td>
              <td></td>
              <td>{fFloat(397_882.79)}</td>
              <td></td>
              <td>{fFloat(429_982.94)}</td>
              <td colSpan={2}></td>
              <td>{fFloat(32_100.15)}</td>
              <td>{fFloat(8.1, 1)}%</td>
            </tr>
          </tfoot>
        </table>
      </ScrollTo>
      <TextBlock>
        <MGCaption>Энергетические ресурсы, используемые АО «МОСГАЗ»</MGCaption>

        <Pie3 idkey={"pie3"}></Pie3>

        <NaviNext
          next={getLink({
            siteUrl,
            pathPrefix,
            filename: "/mos-gaz.accounting.statements.2021.pdf",
          })}
          title="ПРИЛОЖЕНИЕ 2 (PDF) Бухгалтерская отчётность"
          native={true}
        />
      </TextBlock>
    </Tail>
  );
};

export default App1;

export const Head = () => (
  <Seo title="ПРИЛОЖЕНИЕ 1. К ГОДОВОМУ ОТЧЕТУ АО «МОСГАЗ» ЗА 2021 ГОД" />
);
